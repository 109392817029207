import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Labels} from "../../../../internationalization/labels/labels";
import {Icons} from "../../../../icons";
import {EButtonDirective} from "../../../resource/enums/e-button-directive.enum";

@Component({
	selector: 'app-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnChanges {

	_labels = Labels.getLabels();
	_icons = Icons;
	searchText = '';
	_eButtonDirective = EButtonDirective;
	 private _searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');

	@Input() visibleFilter = true;
	@Input() searchFinished = false;
	@Input() showClearAll = false;
	@Input() clear = false;
	@Input() searchDisabled = false;
	@Input() showFilter = false;
	@Input() disableFilter = true;
	@Output() showFilterEvent = new EventEmitter();
	@Output() searchEvent = new EventEmitter<string>();
	@Output() clearAll = new EventEmitter();
	@Output() searchTextChanged = new EventEmitter<string>();

	public searchText$ = this._searchText.asObservable();

	constructor() { }

	ngOnInit(): void {
		this._searchText.subscribe(value => {
			this.searchText = value;
		});
	}

	ngOnChanges() {
		if (!this.disableFilter) {
			this._searchText.next('');
			this.searchDisabled = true;
		}
		if (this.disableFilter) {
			this.searchDisabled = false;
		}
		if (this.clear == true) {
			this.clearAllEvent();
		}
	}

	onSearchTextChange(newText: string): void {
		this._searchText.next(newText);
		this.searchTextChanged.emit(newText);
	}

	doSearch() {
		this.searchEvent.emit(this._searchText.getValue());
	}

	displayFilter() {
		this.showFilter = !this.showFilter;
		this.showFilterEvent.emit(this.showFilter);
	}

	clearAllEvent() {
		this._searchText.next('');
		this.clearAll.emit();
		this.doSearch();
	}
}
