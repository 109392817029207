import { Directive, ElementRef, Input, Output, EventEmitter, HostListener, SimpleChanges, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';  // Importando debounceTime
import { CoreUtil } from '../core-util';

@Directive({
	selector: '[appOffsetWidth]'
})
export class OffsetWidthDirective implements OnDestroy {
	@Input() maxWidth: number;
	@Input() minWidth: number;
	@Input() width: number;

	@Output() widthChange = new EventEmitter<number>();

	private _time: Subscription;
	private _count = 0;
	private _subscriptionList: Subscription[] = [];

	constructor(private element: ElementRef) { }

	ngAfterViewInit() {
		this.schedule();  // Inicializa a verificação após a visualização
	}

	@HostListener('window:resize')  // Ouve o evento de resize da janela
	onResize() {
		this.schedule();
	}

	ngOnChanges(change: SimpleChanges) {
		this.schedule();  // Executa a lógica ao mudar as entradas
	}

	schedule() {
		if (this._time) {
			this._time.unsubscribe();  // Cancela a assinatura anterior
		}

		// Usando debounceTime para evitar chamadas excessivas
		this._time = timer(1).pipe(debounceTime(300)).subscribe(() => {
			let width = this.element.nativeElement.offsetWidth;

			// Verifica se a largura mudou e se o contador ainda está dentro do limite
			if (this.width !== width && this._count < 20) {
				this._count++;

				// Ajusta a largura de acordo com minWidth e maxWidth
				if (this.minWidth && width < this.minWidth) {
					width = this.minWidth;
				}

				if (this.maxWidth && width > this.maxWidth) {
					width = this.maxWidth;
				}

				// Atualiza a largura e emite o evento
				this.width = width;
				this.widthChange.emit(this.width);
			} else {
				this._count = 0;  // Reseta o contador se a largura não mudar
			}
		});

		// Gerencia as assinaturas para garantir que sejam canceladas corretamente
		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, this._time);
	}

	ngOnDestroy() {
		// Cancela todas as assinaturas ao destruir o componente
		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}
}
