export class Icons {
	private static readonly extensions = { png: 'png', svg: 'svg' };
	private static readonly url = '/assets/icon/';

	public static readonly icon = {
		access_time: 'access_time',
		add: 'add',
		add_circle_outline: 'add_circle_outline',
		add_green: 'add_green',
		additional_fee: 'additional-fee',
		agreement_bulk: 'agreement_bulk',
		agreement_icon: 'agreement_icon',
		agreement_blue: 'agreement_blue',
		agreement_management: 'agreement_management',
		alert_gray:'alert_gray',
		alert_yellow:'alert_yellow',
		alert_all_red:'alert_all_red',
		alert_gray_hole: 'alert_gray_hole',
		alert_gray_hole_light: 'alert_gray_hole_light',
		associate: 'Associate',
		associate_gray: 'associate_gray',
		attach_file: 'attach_file',
		attachment_yes: 'attachment_yes',
		attachment_no: 'attachment_no',
		arrow_black_up: 'arrow_black_up',
		arrow_black_down: 'arrow_black_down',
		arrows: 'arrows',
		approval: 'approval',
		architecture: 'architecture',
		barge: 'barge',
		barge_icon: 'barge-icon',
		barge_and_rail_terminals: 'barge_and_rail_terminals',
		booking: "booking-icon",
		booking_management: "booking-management-icon",
		bulk_notification: "bulk-notification-icon",
		cargo_rollover_management: "cargo-rollover-management-icon",
		both: 'both',
		building: 'building',
		business_unity: 'business_unity',
		businessman: 'businessman',
		businessman_blue: 'businessman_blue',
		calendar: 'calendar',
		calendar_agreement_long_term: "calendar_agreement_long_term",
		calendar_coin_agreement_rate_long_term: "calendar_coin_agreement_rate_long_term",
		calendar_truck: 'calendar_truck',
		calendar_validity: 'calendar_validity',
		calendar_validity_png: 'calendar_validity_png',
		capacity_type: 'capacity_type',
		certificate: 'certificate',
		Characteristic: 'Characteristic',
		check: 'check',
		check_green: 'check_green',
		check_won: 'check_won',
		checklist: 'checklist',
		checklist_own_policy_group: 'checklist_own_policy_group',
		cities_and_states: 'cities_and_states',
		clip: 'clip',
		clip_grey: 'clip_grey',
		clone: 'clone',
		close: 'close',
		close_button: 'close_button',
		close_rounded: 'close_rounded',
		coin_agreement_rate: 'coin_agreement_rate',
		commercial: 'commercial',
		commodity: 'commodity',
		company: 'company',
		config: 'config',
		copy: 'copy',
		counteroff: 'counteroff',
		contract: 'contract',
		costing: 'costing',
		costing_blue: 'costing_blue',
		costing_product: 'costing_product',
		costing_product_blue: 'costing_product_blue',
		costing_profile: 'costing_profile',
		commercial_blue: 'commercial_blue',
		create: 'create',
		create_sub_menu: 'create_sub_menu',
		credit_condition_icon: 'credit_condition_icon',
		csv: 'csv',
		customer: 'customer',
		customer_or_sales: 'customer_or_sales',
		date_range: 'date_range',
		delete: 'delete',
		delete_alternative: 'delete-alternative',
		delivery: 'delivery',
		destination_details: 'destination_details',
		destination_restrictions: 'destination_restrictions',
		display: 'display',
		discard_changes: 'discard_changes',
		discharge: 'Discharge',
		disclaimer: 'disclaimer',
		drag_handler: 'drag_handler',
		dollar_sign_blue: 'dollar_sign_blue',
		double_square_icon:'double_square_icon',
		double_square_gray:'double_square_gray',
		down: 'down',
		download: 'download',
		duplicate: 'duplicate',
		edit: 'edit',
		edit_white: 'edit_white',
		error: 'error',
		error_small: 'error_small',
		exchange_rate: 'exchange_rate',
		exit: 'exit',
		expand_more: 'expand_more',
		expire: 'expire',
		export: 'export',
		icon_pdf: 'icon_pdf',
		file_pdf_btn: 'file_pdf_btn',
		file_pdf: 'file_pdf',
		filter: 'filter',
		find_vessel: 'findvessel',
		flag_br: 'flag_br',
		flag_de: 'flag_de',
		flag_en: 'flag_en',
		flag_es: 'flag_es',
		numbers_group: "numbers_group",
		help_circle_gray: 'help_circle_gray',
		hide_teus_tons: 'hide_teus_tons',
		home: 'home',
		icon_corporate_group: 'icon-corporate-group',
		icon_overdue_customer:'icon-overdue-customer',
		icon_overdue_sales: 'icon-overdue-sales',
		icon_renovation: 'icon-renovation',
		image_empty: 'imageempty',
		image_upload: 'ImageUpLoad',
		import: 'import',
		Icon_Route_Approval: 'Icon_Route_Approval',
		info: 'info',
		info_additional: 'info_additional',
		info_alternative: 'info_alternative',
		info_circle: 'info_circle',
		info_icon: 'info_icon',
		info_blue: 'info_blue',
		insurance_blue: 'insurance_blue',
		insurance_booking: 'insurance_booking',
		insurance_ddr: 'insurance-ddr',
		insurance_profile: 'insurance_profile',
		insurer: 'insurer',
		intercab: 'intercab',
		intercab_min: 'intercab-min',
		justification_full_icon: 'justification-full-icon',
		justification_icon: 'justification-icon',
		large_region: 'large_region',
		legal_entity: 'legal_entity',
		less: 'less',
		list_blue: 'list_blue',
		load: 'Load',
		loading: 'loading',
		loading_blue: 'loading_blue',
		locale: 'locale',
		localities_mapping: 'localities_mapping',
		localities_regions: 'localities_regions',
		menu: 'menu',
		modals: 'modals',
		more: 'more',
		more_actions: 'more_actions',
		more_actions_footer: 'more_actions_footer',
		neither: 'neither',
		organize: 'organize',
		operational: 'operational',
		origin_details: 'origin_details',
		origin_restrictions: 'origin_restrictions',
		own_policy: 'own_policy',
		own_policy_group: 'own_policy_group',
		pending_approval: 'pending_approval',
		person: 'person',
		pgr: 'pgr',
		pick_up: 'pick-up',
		pin: 'pin',
		plus_blue:'plus-blue',
		port: 'port',
		ports_list: 'ports_list',
		preferred_routes: 'preferred-routes',
		pricing: 'pricing',
		pricing_blue: 'pricing_blue',
		pricing_architecture: 'pricing-architecture',
		proceed: 'arrow-right',
		proceed_small: 'arrow-right-small',
		proceed_white: 'arrow-right-white',
		proceed_blue: 'arrow-right-blue',
		proceed_blue_circled: 'arrow-right-blue-circled',
		products: 'Products',
		Product_Management: 'Product_Management',
		product_management_blue: 'product_management_blue',
		proforma: 'findvessel',
		product_instance: 'product_instance',
		provisory_plate: 'provisory_plate',
		quotation_cart: 'quotation_cart',
		rail: 'rail',
		rail_icon: 'rail-icon',
		red_alert: 'red_alert',
		refresh: 'refresh',
		refresh_2: 'refresh2',
		rejected: 'rejected',
		remove_circle_outline: 'remove_circle_outline',
		road: 'road',
		routes: 'routes',
		safe_clock: 'safe_clock',
		sales_rep_list:'sales_rep_list',
		schedule: 'schedule',
		search: 'search',
		shopping_cart: 'shopping_cart',
		shopping_cart_red: 'shopping_cart_red',
		sort: 'sort',
		status_icon_approved: 'status-icon-approved',
		status_icon_blocked: 'status-icon-blocked',
		status_icon_blocked_empty: 'status-icon-blocked-empty',
		status_icon_booked: 'status-icon-booked',
		status_icon_booked_empty: 'status-icon-booked-empty',
		status_icon_booked_expired: 'status-icon-booked-expired',
		status_icon_booked_expired_empty: 'status-icon-booked-expired-empty',
		status_icon_closed_lost: 'status-icon-closed-lost',
		status_icon_closed_lost_empty: 'status-icon-closed-lost-empty',
		status_icon_closed_won: 'status-icon-closed-won',
		status_icon_closed_won_empty: 'status-icon-closed-won-empty',
		status_icon_commercial_module_roles: 'status-icon-commercial-module-roles',
		status_icon_countered: 'status-icon-countered',
		status_icon_expired: 'status-icon-expired',
		status_icon_expired_empty: 'status-icon-expired-empty',
		status_icon_edition_rejected: 'status-icon-edition-rejected',
		status_icon_negotiating: 'status-icon-negotiating',
		status_icon_negotiating_empty: 'status-icon-negotiating-empty',
		status_icon_overdue: 'status-icon-overdue',
		status_icon_overdue_customer: 'status-icon-overdue-customer',
		status_icon_overdue_empty: 'status-icon-overdue-empty',
		status_icon_overdue_sales: 'status-icon-overdue-sales',
		status_icon_pending: 'status-icon-pending',
		status_icon_ready: 'status-icon-ready',
		status_icon_ready_empty: 'status-icon-ready-empty',
		status_icon_rejected: 'status-icon-rejected',
		status_icon_table_architecture: 'status-icon-table-architecture',
		status_icon_table_delete: 'status-icon-table-delete',
		status_icon_table_edit: 'status-icon-table-edit',
		status_icon_table_refresh: 'status-icon-table-refresh',
		status_icon_waitlisted: 'status-icon-waitlisted',
		status_icon_waitlisted_empty: 'status-icon-waitlisted-empty',
		status_circle_black: 'status-circle-black',
		status_circle_blue: 'status-circle-blue',
		status_circle_grey: 'status-circle-grey',
		status_circle_green: 'status-circle-green',
		status_circle_red: 'status-circle-red',
		status_circle_yellow: 'status-circle-yellow',
		success: 'success',
		success_small: 'success_small',
		switch: 'switch',
		target: 'target',
		terminal: 'terminal',
		three_dots_green: 'three_dots_green',
		three_dots_grey: 'three_dots_grey',
		transport: 'transport',
		truck_loading: 'truck_loading',
		transshipment: 'Transshipment',
		upload: 'upload',
		validate_checklist: 'validate_checklist',
		vendor_blue: 'vendor_blue',
		vendor_information: 'vendor-information',
		vessel_capacity: 'vessel_capacity',
		view: 'view',
		view_capacity: 'view_capacity',
		warning: 'warning',
		warning_blue: 'warning_blue',
		watch: 'watch',
		watch_grey: 'watch_grey',
		warning_clock: 'warning_clock',
		warning_red: 'warning_red',
		wiki_blue: 'wiki_blue',
		x: 'x',
		x_png: 'x_png',
		x_lost: 'x_lost',
		x_red: 'x_red',
		xls: 'xls',
		xls_clock: 'xls-clock',
		vessel: 'vessel',
		eventAvaliable: 'eventAvaliable',
		previousCondition: 'previous_condition',
		eye: 'eye',
		new_eye: 'new_eye'
	};

	public static getIconPath(icon, extension = this.extensions.svg) {
		return this.url + icon + '.' + extension;
	}
}