import {Component, Input, OnInit} from '@angular/core';
import {Icons} from '../../../../icons';
import {TooltipPosition} from '@angular/material';

@Component({
	selector: 'app-status-icon',
	templateUrl: './status-icon.component.html',
	styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit {

	@Input() icon: string;
	@Input() scndIcon: string;
	@Input() textContent: string;
	@Input() tooltipTextContent: string;
	@Input() scndTooltipTextContent: string;
	@Input() showTooltip: boolean = false;
	@Input() showScndTooltip: boolean = false;
	@Input() showLabel: boolean = false;
	@Input() tooltipPosition: TooltipPosition = 'above';
	@Input() scndTooltipPosition: TooltipPosition = 'above';
	@Input() colorText: string = '#335C92';
	@Input() fontSizeText: string = '16px';
	@Input() iconAfterText: boolean = false;
	@Input() isBullet: boolean;
	@Input() colorId: number = 0;
	@Input() showLabelTooltip: boolean = false;
	@Input() isCentered: boolean = true;


	_icons = Icons;

	constructor() {
	}

	ngOnInit(): void {
	}

	getTooltip(): string {
		if (this.tooltipTextContent) {
			return this.tooltipTextContent;
		}

		return this.showTooltip ? this.textContent : null;
	}

	getScndTooltip(): string {
		if (this.scndTooltipTextContent) {
			return this.scndTooltipTextContent;
		}

		return this.showScndTooltip ? this.scndTooltipTextContent : null;
	}
}