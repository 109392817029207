import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {LoginService} from 'src/app/resource/service/login.service';
import {Constants} from 'src/constants';
import {environment} from 'src/environments/environment';
import {LocaleSwitcher} from 'src/internationalization/locale-switcher';
import {ObjectMultiFilter} from '../dto/object-multi-filter.dto';
import {Pagination} from '../dto/pagination';

@Injectable({
	providedIn: 'root'
})
export class HttpClientService {
	constructor(private http: HttpClient, private loginService: LoginService, private router: Router) {
	}

	private getHttpOptions(contentType?: string, accept?: string, responseType?: any) {
		return {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('msal.idtoken'),
				'Consumer-Key': environment.consumerkey,
				'Content-Type': contentType ? contentType : 'application/json',
				locale: LocaleSwitcher.getLocale(),
				Accept: accept ? accept : 'application/json',
				'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}),
			responseType: responseType
		};
	}

	getUrl(url: string): Observable<any> {
		return this.http.get<any>(url, this.getHttpOptions());
	}

	getList(microService: string, endPoint: string, strSearch: string, pagination: Pagination,
					params: string[], mapFilter: Map<string, ObjectMultiFilter>, sortByAttribute: string = '', queryParams?: string, formatDate?: boolean): any {
		let url = `${this.getServiceUrl(microService)}${endPoint}`;

		if (strSearch && strSearch != '') {
			if (url.includes('?')) {
				url += `&strSearch=${strSearch}`;
			} else {
				url += `?strSearch=${strSearch}`;
			}
		}

		if (sortByAttribute && sortByAttribute != '') {
			if (url.includes('?')) {
				url += `&sortByAttribute=${sortByAttribute}`;
			} else {
				url += `?sortByAttribute=${sortByAttribute}`;
			}
		}

		if (pagination) {
			if (url.includes('?')) {
				url += `&page=${pagination.page}&pageSize=${pagination.pageSize}`;
			} else {
				url += `?page=${pagination.page}&pageSize=${pagination.pageSize}`;
			}
		}

		if (queryParams && queryParams != '') {
			if (queryParams && (queryParams.startsWith('?') || queryParams.startsWith('&'))) {
				queryParams = queryParams.substr(1);
			}

			if (url.includes('?')) {
				url += `&${queryParams}`;
			} else {
				url += `?${queryParams}`;
			}
		}
		return this.doGetList(this.checkIfHasDate(url.replace('/?', '?').replace('?&', '?'), formatDate), params, mapFilter, microService);
	}

	getListWithoutCheckIfHasDate(microService: string, endPoint: string, strSearch: string, pagination: Pagination,
															 params: string[], mapFilter: Map<string, ObjectMultiFilter>, sortByAttribute: string = '', queryParams?: string): any {
		let url = `${this.getServiceUrl(microService)}${endPoint}`;

		if (strSearch && strSearch != '') {
			if (url.includes('?')) {
				url += `&strSearch=${strSearch}`;
			} else {
				url += `?strSearch=${strSearch}`;
			}
		}

		if (sortByAttribute && sortByAttribute != '') {
			if (url.includes('?')) {
				url += `&sortByAttribute=${sortByAttribute}`;
			} else {
				url += `?sortByAttribute=${sortByAttribute}`;
			}
		}

		if (pagination) {
			if (url.includes('?')) {
				url += `&page=${pagination.page}&pageSize=${pagination.pageSize}`;
			} else {
				url += `?page=${pagination.page}&pageSize=${pagination.pageSize}`;
			}
		}

		if (queryParams && queryParams != '') {
			if (queryParams && (queryParams.startsWith('?') || queryParams.startsWith('&'))) {
				queryParams = queryParams.substr(1);
			}

			if (url.includes('?')) {
				url += `&${queryParams}`;
			} else {
				url += `?${queryParams}`;
			}
		}
		return this.doGetList(url, params, mapFilter);
	}

	getListPlusFilter(microService: string, endPoint: string, strSearch: string, customerTypeId: number, pagination: Pagination,
										params: string[], mapFilter: Map<string, ObjectMultiFilter>, sortByAttribute: string = '', queryParams?: string): any {
		let url = `${this.getServiceUrl(microService)}${endPoint}`;

		if (strSearch && strSearch != '') {
			if (url.includes('?')) {
				url += `&strSearch=${strSearch}`;
			} else {
				url += `?strSearch=${strSearch}`;
			}
		}

		if (customerTypeId) {
			if (url.includes('?')) {
				url += `&customerTypeId=${customerTypeId}`;
			} else {
				url += `?customerTypeId=${customerTypeId}`;
			}
		}

		if (sortByAttribute && sortByAttribute != '') {
			if (url.includes('?')) {
				url += `&sortByAttribute=${sortByAttribute}`;
			} else {
				url += `?sortByAttribute=${sortByAttribute}`;
			}
		}

		if (pagination) {
			if (url.includes('?')) {
				url += `&page=${pagination.page}&pageSize=${pagination.pageSize}`;
			} else {
				url += `?page=${pagination.page}&pageSize=${pagination.pageSize}`;
			}
		}

		if (queryParams && queryParams != '') {
			if (queryParams && (queryParams.startsWith('?') || queryParams.startsWith('&'))) {
				queryParams = queryParams.substr(1);
			}

			if (url.includes('?')) {
				url += `&${queryParams}`;
			} else {
				url += `?${queryParams}`;
			}
		}
		return this.doGetList(this.checkIfHasDate(url.replace('/?', '?').replace('?&', '?')), params, mapFilter);
	}

	getListGroup(microService: string, endPoint: string, strSearch: string, pagination: Pagination,
							 params: string[], mapFilter: Map<string, ObjectMultiFilter>, sortByAttribute: string = '', queryParams?: string) {

		let url = `${this.getServiceUrl(microService)}${endPoint}`;

		if (strSearch && strSearch != '') {
			if (url.includes('?')) {
				url += `&strSearch=${strSearch}`;
			} else {
				url += `?strSearch=${strSearch}`;
			}
		}

		if (queryParams && queryParams != '') {
			if (queryParams && (queryParams.startsWith('?') || queryParams.startsWith('&'))) {
				queryParams = queryParams.substr(1);
			}

			if (url.includes('?')) {
				url += `&${queryParams}`;
			} else {
				url += `?${queryParams}`;
			}
		}

		return this.doGetList(this.checkIfHasDate(url.replace('/?', '?').replace('?&', '?')), params, mapFilter);
	}

	private doGetList(url: string, params: string[], mapFilter: Map<string, ObjectMultiFilter>, microService?) {
		const queryParams = {};

		if (params && mapFilter) {
			for (const filter of mapFilter) {
				const match = filter[0].match(/(?:\d+\.)?(?:\d+\.)?(.+)/);
				const key = match ? match[1] : null;
				const obj: ObjectMultiFilter = filter[1];

				if (!obj.filter) {
					if (!queryParams[key] && !obj.isGroup) {
						queryParams[key] = [];
					}

					if (obj.allSelected && microService === 'customer') {
						queryParams[key] = ['0'];
					} else if (obj.value) {
						queryParams[key] = [obj.value];
					} else if (obj.arrayValue) {
						if (obj.isGroup) {
							obj.arrayGroupParams.forEach(groupKey => {
								if (!queryParams[groupKey]) queryParams[groupKey] = [];
								queryParams[groupKey] = queryParams[groupKey]
									.concat(obj.arrayValue.filter(a => a.groupParam === groupKey)
										.map(a => a[obj.idProperty]));
							});
						} else {
							const filterByDescription =
								[
									"Tax ID (CPF/CNPJ)",
									"CMD ID",
									"Approved Limit",
									"Approved Amount",
								];
							if (filterByDescription.includes(obj.placeholder)) {
								obj.idProperty = 'description'
							}
							// Start - Version 1.9 - newFieldsCostList
							if (obj.propFilter) {
								obj.idProperty = obj.propFilter;
							}
							// Final - Version 1.9
							queryParams[key] = queryParams[key]
								.concat(obj.arrayValue
									.map(a => a[obj.idProperty]));
						}
					} else {
						queryParams[key] = queryParams[key].concat(obj.arrayValue.map(a => a[obj.idProperty]));
					}

				} else if (obj.filter.filter) {
					if (!Array.isArray(obj.filter.value)) {
						if (!queryParams[obj.filter.filter[obj.filter.id]]) {
							queryParams[obj.filter.filter[obj.filter.id]] = [];
						}

						if (obj.filter.value) {
							queryParams[obj.filter.filter[obj.filter.id]].push(obj.filter.value);
						}
					} else if (obj.filter.filter.singleParam) {
						queryParams[obj.filter.filter[obj.filter.id]] = obj.filter.value;
					} else {
						const keys = obj.filter.filter[obj.filter.id].split('_');

						for (let i = 0; i < keys.length; i++) {
							if (!queryParams[keys[i]]) {
								queryParams[keys[i]] = [];
							}

							queryParams[keys[i]].push(obj.filter.value[i]);
						}
					}
				}
			}

			for (const query in queryParams) {
				queryParams[query] = queryParams[query].filter((value, index, self) => {
					return self.indexOf(value) === index;
				});

				if (queryParams[query].filter(f => f).length > 0) {
					let array = queryParams[query];

					if (queryParams[query] && queryParams[query].find(q => q.id >= 0)) {
						array = queryParams[query].map(q => q.id);
					}

					if (url.includes('?')) {
						url += `&${query}=${array.join(',')}`;
					} else {
						url += `?${query}=${array.join(',')}`;
					}
				}
			}
		}
		return this.http.get(url.replace('/?', '?').replace('?&', '?'), this.getHttpOptions());
	}

	get(microService: string, endPoint: string, queryParams: string, contentType?: string, accept?: string, responseType: 'json' | 'blob' = 'json'): Observable<any> {
		let url = `${this.getServiceUrl(microService)}${endPoint}`;

		if (queryParams && queryParams != '') {
			if (queryParams && (queryParams.startsWith('?') || queryParams.startsWith('&'))) {
				queryParams = queryParams.substr(1);
			}

			if (url.includes('?')) {
				url += `&${queryParams}`;
			} else {
				url += `?${queryParams}`;
			}
		}

		return this.http.get<any>(url.replace('/?', '?').replace('?&', '?'), this.getHttpOptions(contentType, accept, responseType));
	}

	getById(microService: string, endPoint: string, id: number): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}/${id}`;

		return this.http.get<any>(url, this.getHttpOptions());
	}

	getByIdWithParams(microService: string, endPoint: string, id: number, queryParams: string): Observable<any> {
		let url = `${this.getServiceUrl(microService)}${endPoint}/${id}`;
		if (queryParams && queryParams != '') {
			if (queryParams && (queryParams.startsWith('?') || queryParams.startsWith('&'))) {
				queryParams = queryParams.substr(1);
			}
			if (url.includes('?')) {
				url += `&${queryParams}`;
			} else {
				url += `?${queryParams}`;
			}
		}
		return this.http.get<any>(url, this.getHttpOptions());
	}

	patch(microService: string, endPoint: string, id: number, object: any): Observable<any> {
		let url;
		if (id) {
			url = `${this.getServiceUrl(microService)}${endPoint}/${id}`;
		} else {
			url = `${this.getServiceUrl(microService)}${endPoint}`;
		}
		return this.http.patch<any>(url, object, this.getHttpOptions());
	}

	patchPayment(microService: string, endPoint: string, object: any): Observable<any> {
		let url;
		url = `${this.getServiceUrl(microService)}${endPoint}`;
		return this.http.patch<any>(url, object, this.getHttpOptions());
	}

	patchWithoutEndpoint(microService: string, id: number, object: any): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${id}`;

		return this.http.patch<any>(url, object, this.getHttpOptions());
	}

	patchWithoutId(microService: string, endPoint: string, object: any): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}`;

		return this.http.patch<any>(url, object, this.getHttpOptions());
	}

	post(microService: string, endPoint: string, object: any, complement?: string, contentType?: string, accept?: string, responseType: 'json' | 'blob' = 'json'): Observable<any> {
		let url = '';

		if (complement && complement != '') {
			url = `${this.getServiceUrl(microService)}${endPoint}${complement}`;
		} else {
			url = `${this.getServiceUrl(microService)}${endPoint}`;
		}

		return this.http.post<any>(url.replace('/?', '?').replace('?&', '?'), object, this.getHttpOptions(contentType, accept, responseType));
	}

	postFile(microService: string, endPoint: string, object: any): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}`;
		const headers = new HttpHeaders({
			Authorization: 'Bearer ' + localStorage.getItem('msal.idtoken'),
			'Consumer-Key': environment.consumerkey,
			locale: LocaleSwitcher.getLocale(),
			'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
		})
		return this.http.post<any>(url, object, {headers: headers});
	}

	patchFile(microService: string, endPoint: string, object: any): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}`;
		const headers = new HttpHeaders({
			Authorization: 'Bearer ' + localStorage.getItem('msal.idtoken'),
			'Consumer-Key': environment.consumerkey,
			locale: LocaleSwitcher.getLocale(),
			'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
		});
		return this.http.patch<any>(url, object, {headers: headers});
	}

	postSimple(microService: string, object: any): Observable<any> {
		const endPoint = `${this.getServiceUrl(microService)}`;
		const url = endPoint.substring(0, endPoint.length - 1);
		return this.http.post<any>(url, object, this.getHttpOptions());
	}

	postList(microService: string, endPoint: string, object: any, pagination: Pagination, complement?: string): Observable<any> {
		let url = '';

		if (complement && complement != '') {
			url = `${this.getServiceUrl(microService)}${endPoint}${complement}`;
		} else {
			url = `${this.getServiceUrl(microService)}${endPoint}`;
		}

		if (pagination) {
			if (url.includes('?')) {
				url += `&page=${pagination.page}&pageSize=${pagination.pageSize}`;
			} else {
				url += `?page=${pagination.page}&pageSize=${pagination.pageSize}`;
			}
		}

		return this.http.post<any>(url.replace('/?', '?').replace('?&', '?'), object, this.getHttpOptions());
	}

	patchCSV(microService: string, endPoint: string, object: any, complement?: string): Observable<any> {
		let url = '';
		if (complement && complement != '') {
			url = `${this.getServiceUrl(microService)}${endPoint}${complement}`;
		} else {
			url = `${this.getServiceUrl(microService)}${endPoint}`;
		}
		let httpOptions = this.getHttpOptions();
		httpOptions['responseType'] = 'arraybuffer';
		return this.http.patch<any>(url.replace('/?', '?').replace('?&', '?'), object, httpOptions);
	}

	put(microService: string, endPoint: string, object: any): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}`;

		return this.http.put(url, object, this.getHttpOptions());
	}

	delete(microService: string, endPoint: string, id: number): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}/${id}`;

		return this.http.delete<any>(url, this.getHttpOptions());
	}

	deleteWithoutId(microService: string, endPoint: string): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}`;

		return this.http.delete<any>(url, this.getHttpOptions());
	}

	deleteWithoutParams(microService: string, id: number): Observable<any> {
		const url = `${this.getServiceUrl(microService)}/${id}`;

		return this.http.delete<any>(url, this.getHttpOptions());
	}

	deleteWithParams(microService: string, endPoint: string, params: string): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}/${params}`;

		return this.http.delete<any>(url.replace('/?', '?').replace('?&', '?'), this.getHttpOptions());
	}

	deleteWithBody(microService: string, endPoint: string, object: any): Observable<any> {
		const url = `${this.getServiceUrl(microService)}${endPoint}`;

		return this.http.request('delete', url, {body: object, headers: this.getHttpOptions().headers});
	}

	getHistoricList(microService: string, endPoint: string, idQuery: string, fromDate, toDate, userIdArray: any[], pagination: Pagination) {
		let queryParams = `${idQuery}&ascendingOrder=${false}`;

		if (fromDate && toDate) {
			queryParams += `&fromDate=${fromDate}&toDate=${toDate}`;
		}

		if (userIdArray) {
			queryParams += `&userIdArray=${userIdArray.map(user => user.id).join(',')}`;
		}

		if (pagination) {
			queryParams += `&page=${pagination.page}&pageSize=${pagination.pageSize}`;
		}

		return this.get(microService, endPoint, queryParams);
	}

	downloadFile(microService: string, params: HttpParams, endPoint: string = 'download'): Observable<any> {
		const url = `${this.getServiceUrl(microService)}`;
		return this.http.get<any>(url + endPoint, {
			params: params, responseType: 'blob' as 'json',
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('msal.idtoken'),
				'Consumer-Key': environment.consumerkey,
				locale: LocaleSwitcher.getLocale(),
				Accept: '*/*'
			})
		});
	}

	uploadFile(microService: string, file: FormData, params: HttpParams): Observable<any> {
		const url = `${this.getServiceUrl(microService)}`;
		return this.http.post<any>(url + 'upload', file, {
			params: params,
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('msal.idtoken'),
				'Consumer-Key': environment.consumerkey,
				locale: LocaleSwitcher.getLocale(),
				Accept: 'application/json'
			})
		});
	}

	deleteFile(microService: string, params: HttpParams) {
		const url = `${this.getServiceUrl(microService)}`;
		return this.http.delete<any>(url + 'delete', {params: params, headers: this.getHttpOptions().headers});
	}

	getServiceUrl(microService?: string): string {
		switch (microService) {
			case Constants.constant.microServices.authorizer:
				return environment.authorizerHost;
			case Constants.constant.microServices.customer:
				return environment.customerHost;
			case Constants.constant.microServices.additional_fees:
				return environment.additionalFeesHost;
			case Constants.constant.microServices.commercial:
				return environment.commercial;
			case Constants.constant.microServices.costing:
				return environment.costingHost;
			case Constants.constant.microServices.costingModule:
				return environment.costingModuleHost;
			case Constants.constant.microServices.historic:
				return environment.historicHost;
			case Constants.constant.microServices.general_register:
				return environment.generalRegisterHost;
			case Constants.constant.microServices.vessel:
				return environment.vesselHost;
			case Constants.constant.microServices.pricing:
				return environment.pricing;
			case Constants.constant.microServices.schedule:
				return environment.scheduleHost;
			case Constants.constant.microServices.locality_mapper:
				return environment.localityMapperHost;
			case Constants.constant.microServices.vessel_schedule:
				return environment.scheduleHost;
			case Constants.constant.microServices.product:
				return environment.productHost;
			case Constants.constant.microServices.file:
				return environment.azfUploadFilesToBlob;
			case Constants.constant.microServices.vesselV2:
				return environment.vesselHostV2;
			case Constants.constant.microServices.proforma:
				return environment.proformaHost;
			case Constants.constant.microServices.scheduleMaersk:
				return environment.scheduleMaerskHost;
			case Constants.constant.microServices.postpone:
				return environment.postponeUri;
			default:
				return 'microservice_not_found';
		}
	}

	getHttpClient() {
		return this.http;
	}

	private checkIfHasDate(url: string, formatDate?: boolean): string {
		let isValid = false;
		const regexExp = /((\d{4}(\-|\/)\d{2}(\-|\/)\d{2}|\d{2}(\-|\/)\d{2}(\-|\/)\d{4})|\d{2}:\d{2}(:\d{2})*)/g;
		const dateExp = /(\d{4}(\-|\/)\d{2}(\-|\/)\d{2}|\d{2}(\-|\/)\d{2}(\-|\/)\d{4})/;
		const timeExp = /\d{2}:\d{2}(:\d{2})*/;
		const matchResult = url.match(regexExp);

		if (matchResult && !formatDate) {
			matchResult.forEach(timeStr => {
				let splittedUrl: string[] = [];

				if (timeStr.match(dateExp)) {
					const splittedDate = timeStr.indexOf('-') >= 0 ? timeStr.split('-') : timeStr.split('/');
					const finalDate = splittedDate[0].length > splittedDate[2].length ?
						splittedDate.join('-') :
						splittedDate.reverse().join('-');
					splittedUrl = url.split(timeStr);
					url = splittedUrl.join(finalDate);

					isValid = this.isValidDate(finalDate);
				} else if (timeStr.match(timeExp)) {
					let splittedTime = timeStr.split(':');
					if (splittedTime.length >= 3) splittedTime = [splittedTime[0], splittedTime[1]];
					const finalTime = splittedTime.join(':');
					splittedUrl = url.split(timeStr);
					url = splittedUrl.join(finalTime);

					isValid = this.isValidTime(splittedTime);
				}
			});
		}

		return url;
	}

	private isValidDate(finalDate: string): boolean {
		// TODO: Sprint13 - On future, user'll be adviced that the date s/he's trying to search is bad formatted
		const tempMoment = moment(finalDate);

		return (!!tempMoment.date() && !!tempMoment.month() && !!tempMoment.year());
	}

	private isValidTime(splittedTime: string[]): boolean {
		// TODO: Sprint13 - On future, user'll be adviced that the hour s/he's trying to search is bad formatted
		const hour = parseInt(splittedTime[0]);
		const minute = parseInt(splittedTime[1]);

		return hour >= 0 && hour <= 24 && minute >= 0 && minute <= 60;
	}

	handlingSpecialCharacters(strSearch: string): string {
		for (let i = 0; i < strSearch.length; i++) {
			if (strSearch[i] == '&')
				strSearch = strSearch.replace('&', '%26');
			if (strSearch[i] == '+')
				strSearch = strSearch.replace('+', '%2B');
			if (strSearch[i] == '[')
				strSearch = strSearch.replace('[', '%5B');
			if (strSearch[i] == ']')
				strSearch = strSearch.replace(']', '%5D');
			if (strSearch[i] == '{')
				strSearch = strSearch.replace('{', '%7B');
			if (strSearch[i] == '}')
				strSearch = strSearch.replace('}', '%7D');
			if (strSearch[i] == '|')
				strSearch = strSearch.replace('|', '%7C');
			if (strSearch[i] == '^')
				strSearch = strSearch.replace('^', '%5E');
			if (strSearch[i] == '\\')
				strSearch = strSearch.replace('\\', '%5C');
		}
		return strSearch;
	}
}