import { Directive, Input, Output, EventEmitter, ElementRef, HostListener, SimpleChanges, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {CoreUtil} from "../core-util";

@Directive({
	selector: '[appOffsetHeight]'
})
export class OffsetHeightDirective implements OnDestroy {
	@Input() height: number;
	@Output() heightChange = new EventEmitter<number>();

	private _time: Subscription;
	private _count = 0;
	private _subscriptionList: Subscription[] = [];

	constructor(private element: ElementRef) { }

	ngAfterViewInit() {
		this.schedule();  // Initialize height check after preview
	}

	@HostListener('window:resize')  // Listen to the window resize event
	onResize() {
		this.schedule();
	}

	ngOnChanges(change: SimpleChanges) {
		this.schedule();  // Execute logic when changing inputs
	}

	schedule() {
		if (this._time) {
			this._time.unsubscribe();  // Cancel the previous subscription
		}

		// Using debounceTime to avoid excessive calls
		this._time = timer(1).pipe(debounceTime(300)).subscribe(() => {
			let height = this.element.nativeElement.offsetHeight;

			// Check if the height has changed and the counter is still within the limit
			if (this.height !== height && this._count < 20) {
				this._count++;

				// Update the height and emit the event
				this.height = height;
				this.heightChange.emit(this.height);
			} else {
				this._count = 0; // Reset the counter if the height doesn't change
			}
		});

		// Gerencia as assinaturas para garantir que sejam canceladas corretamente
		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, this._time);
	}

	ngOnDestroy() {
		// Manage subscriptions to ensure they are canceled correctly
		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}

}
