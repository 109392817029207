export default {
    redirectUri: 'https://intercab-pp.alianca.com.br/',
    apiUri: 'https://api-stage.alianca.com.br/intercab',
	  apiMaerskUri: 'https://api-stage.maersk.com/intercab',
    fileReference: 'environment.preprod.ts',
    ambient: 'preprod' as const,
    version: '1.8.0',
    consumerkey: 'Ra1999xIZfpendA0yV8ASM2vN71faiem',
    instrumentationKey: '30287add-fbe6-4f4e-a51a-c04a3b7f5f94',
    azfUri: 'https://api-stage.alianca.com.br/intercab/function/uploadfilestoblob',
    commercialUri: 'https://api-stage.alianca.com.br/intercab' + '/v1/commercial/',
		postponeUri: 'https://api-stage.alianca.com.br/intercab/postponed-validity/v1/postponed-validity/agreement/'
}

